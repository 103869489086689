<template>
  <div v-html="pageHtml" class="statement-read" />

</template>

<script>
import { PreviewByContent } from '@/api/enterprise/statement'

export default {
  name: 'StatementRead',
  data() {
    return {
      pageH: `${document.body.clientHeight}px`,
      accessToken: localStorage.getItem('accessToken'),
      id: this.$route.query.id,
      iframeSrc: '',
      pageHtml: '',
      type: Number(this.$route.query.t),
    }
  },
  created() {
    if (this.id) {
      this.previewByContent()
    } else {
      this.pageHtml = localStorage.getItem('htmlStr')
    }
  },
  methods: {
    previewByContent() {
      PreviewByContent(this.$route.query.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.pageHtml = resData.data
        }
      })
    },
  },
}
</script>

<style lang="scss">
 .statement-read{
   width: 1200px;
   margin: 20px auto;
   table{
     width: 100%;
     table-layout: fixed;
     box-sizing: border-box;
     border-spacing: 0;
     page-break-inside: avoid!important;
     border-collapse: collapse;
   }
   td,th{
     box-sizing: border-box;
     border: 1px solid #666666;
     page-break-inside: avoid!important;
   }
 }

</style>
